import { Colors, Icon, Icons, Label } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { GoogleReview } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';

export interface GoogleReviewsProps {
  reviews?: GoogleReview[];
  reviewsCount?: number;
  rating?: number;
  starRating?: number;
}

interface RatingSummaryProps extends ClassNameProps {
  rating: number;
  starRating: number;
  reviewsCount: number;
}
const RatingSummary = ({ rating, reviewsCount, starRating, className }: RatingSummaryProps) => {
  const { t } = useTranslation();

  const filledIcon: Icons = 'Markers/StarFilled';
  const halfIcon: Icons = 'Markers/StarHalf';
  const emptyIcon: Icons = 'Markers/StarEmpty';
  const ratingColor: keyof Colors = 'iconGooglereview';

  return (
    <div
      className={className}
      sx={{ display: 'flex', gap: '3xs' }}
    >
      <Label variant="extraextralarge">{rating}</Label>

      <div sx={{ display: 'flex', flexDirection: 'column' }}>
        <span>
          {[...Array(5)].map((_, idx) => {
            let iconName: Icons = filledIcon;
            if (idx < starRating && idx + 1 > starRating) {
              iconName = halfIcon;
            }
            if (idx >= starRating) {
              iconName = emptyIcon;
            }

            return (
              <Icon
                key={idx}
                size="12"
                name={iconName}
                color={ratingColor}
              />
            );
          })}
        </span>
        <Label
          variant="extrasmall"
          sx={{ color: 'textDimmedmedium' }}
        >
          {t('hotel.reviews', { count: reviewsCount })}
        </Label>
      </div>
    </div>
  );
};

export const GoogleReviews: React.FC<GoogleReviewsProps> = ({
  reviews = [],
  reviewsCount = 0,
  starRating = 0,
  rating = 0,
}) => {
  const { t } = useTranslation();

  return (
    <div sx={{ padding: 'l' }}>
      <div
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '3xs',
          marginBottom: 's',
        }}
      >
        <Icon
          name="Brands/GoogleFilled"
          size="40"
        />
        <Label variant="extralarge">{t('hotelDetails.tabs.googleReviews')}</Label>
      </div>
      <RatingSummary
        rating={rating}
        reviewsCount={reviewsCount}
        starRating={starRating}
        sx={{ marginBottom: 'l' }}
      />

      {reviews.map(({ authorName, rating, relativeTimeDescription, text }, index) => (
        <div
          key={index}
          sx={{
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'backgroundLightneutral',
            borderRadius: '12',
            marginBottom: 'l',
            padding: 'l',
            boxShadow: 'bottom',
          }}
        >
          <div
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 's',
            }}
          >
            <span>
              <Label variant="small">{authorName}</Label>
              <Label
                variant="smallbold"
                sx={{ display: 'block' }}
              >
                {relativeTimeDescription}
              </Label>
            </span>
            <Label variant="largebold">{rating}/5</Label>
          </div>
          <Label variant="small">{text}</Label>
          <div />
        </div>
      ))}
    </div>
  );
};
